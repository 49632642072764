import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  },
  running2: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`,
    marginBottom: `2rem`
  }
}

export default () => (
  <>
    <Section>
    <Heading variant='h1' as='h2' sx={styles.heading}>
      A <span>World-Class</span> Approach To All The Content You'll Ever Need 🚀
      </Heading>
      <Heading variant='h3' as='p' sx={styles.running2}>
      Our agency plans, produces, publishes and promotes 
content for market leading brands.
      </Heading>
    </Section>
    <Box variant='buttons.group' sx={styles.buttons}>
      <Button as={Link} to='https://upmycontent.spp.io/'>
        Get Started
      </Button>
      <Button variant='white' as={Link} to='/authors'>
        About Us
      </Button>
    </Box>
  </>
)
