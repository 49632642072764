import React from 'react'
import { Stack, Main, PreFooter } from '@layout'
import Card from '@components/Card'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import DividerSmaller from '@components/DividerSmaller'
import Seo from '@widgets/Seo'
import BannerHorizontal from '@widgets/BannerHorizontal'
import Categories from '@widgets/Categories'
import ContactForm from '@widgets/ContactForm'
import Testimonial from '@widgets/Testimonial'
import IconButton from '@components/IconButton'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import Hero from '../components/Hero/Hero'
import { useBlogCategories } from '@helpers-blog'
import ServicesColumn from '@widgets/ServicesColumn'
import { FaChartLine } from 'react-icons/fa'
import { RiArticleLine } from 'react-icons/ri'
import { RiLightbulbLine } from 'react-icons/ri'
import { RiRocket2Line } from 'react-icons/ri'
import { RiMoneyDollarCircleLine } from 'react-icons/ri'
import { RiChatSmile2Line } from 'react-icons/ri'
import { RiCustomerService2Line } from 'react-icons/ri'
import { Flex, Box, Heading, css } from 'theme-ui'
import HeroBoxed from '@widgets/HeroBoxed'
import MemphisPattern from '@components/MemphisPattern'
import ContentSubheadingAndTitle from '@components/ContentSubheadingAndTitle'
import Photo2 from '@widgets/Testimonial/Testimonial.Photo2'
import Photo3 from '@widgets/Testimonial/Testimonial.Photo3'
import PageTitle from '@components/PageTitle'


const Posts = ({ data: { posts = {}, featuredPosts = {} }, ...props }) => {
  const { pageContext: { services = {} } = {} } = props
  const categories = useBlogCategories()

  return (
    <>
      <Seo title='Fuel Your Online Presence With World-Class Content' />
      <Stack>
        <Main>
        <Box className='aids'><DividerSmaller  space={4} /></Box>
          <Hero />
        </Main>
      </Stack>
      <Stack>
        <Main>
          <Divider space={2} />
          <Box p={0} bg='primary' sx={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'stretch', display: 'inline-block' }}>
          <IconButton to='contact' name='Authority Content' Icon={ RiArticleLine } iconPath='../../Gatsby-Logo.svg' variant='horizontal' />
          <IconButton to='#' name='Sales Copywriting' Icon={ RiMoneyDollarCircleLine } iconPath="/Gatsby-Logo.svg" variant='horizontal' />
          <IconButton to='#' name='Website Launch Package' Icon={ RiRocket2Line } iconPath='/Gatsby-Logo.svg' variant='horizontal' />
          <IconButton to='content-strategy' name='Content Strategy' Icon={ RiLightbulbLine } iconPath='/Gatsby-Logo.svg' variant='horizontal' />
          </Box>

        <Box className='aids'><DividerSmaller  space={4} /></Box>


      <Box className='aids'><DividerSmaller  space={4} /></Box>
        <HeroBoxed.Wrapper>
        <HeroBoxed.RightColumn>
        <Photo2 />
        </HeroBoxed.RightColumn>
          <HeroBoxed.LeftColumn>
          <Box className='after-hero'>
            <PageTitle
          header="We're Here For You"
          subheader='There’s definitely a pair of shoes for everyone. 
          For shoe enthusiasts, the more luxurious and unique a shoe’s style is, the more it piques their interest. 
          If you’re willing to pay an extra price for the shoes that bring dressing up to a different level, Belvedere shoes are for you.'
            />
          </Box>
          </HeroBoxed.LeftColumn>
          <MemphisPattern />
        </HeroBoxed.Wrapper>
        </Main>
        </Stack>
        <Stack>
          <Main>
        <Box className='aids'><DividerSmaller  space={3} /></Box>
        <Divider />
        <Box className="aidstest" sx={{paddingRight:'8px', minWidth:'75%', display:'inline-block', flex:1, flexDirection:'row'}}>
        <HeroBoxed.Wrapper >


<Box sx={{height:20}}></Box>
        <h2 className="css-1j3qyi1" >SEO Services</h2>
        <p>We can make SEO a revenue driver for your business. Explore our <br />
          search engine optimization services to learn more!</p>
        
<br />




        </HeroBoxed.Wrapper>
        </Box>
        <Box sx={{display:'inline-block', maxWidth:538, flex:1, flexDirection: 'column'}}>
        <Box sx={{flex:1, flexDirection: 'column'}}>
        <IconButton to='contact' name='SEO Consulting' Icon={ RiArticleLine } iconPath='../../Gatsby-Logo.svg' variant='horizontal' />
        </Box>
        <Box sx={{flex:1, flexDirection: 'column'}}>
        <IconButton to='#' name='Link Building' Icon={ RiMoneyDollarCircleLine } iconPath="/Gatsby-Logo.svg" variant='horizontal' />
        </Box>
        </Box>
          </Main>
        </Stack>

        <Box className='aids'><DividerSmaller  space={4} /></Box>

  <Stack>
    <Main>
      <Box className='aids'><DividerSmaller  space={4} /></Box>
        <HeroBoxed.Wrapper>
        <HeroBoxed.RightColumn>
        <Photo3 />
        </HeroBoxed.RightColumn>
          <HeroBoxed.LeftColumn>
          <Box className='after-hero'>
            <PageTitle
          header="24/5 FREE Customer Support"
          subheader='Our staff is ready to assist you with any issue, any time, 24/5.'
            />
            <Box sx={{maxWidth:300, display:'inline-block'}}>
          <IconButton to='/contact' name='Submit a Support Ticket' Icon={ RiCustomerService2Line } variant='vertical' className='ass23' /></Box>
          <Box sx={{width:10, display:'inline-block'}}></Box>
          <Box sx={{maxWidth:300, display:'inline-block'}}>
          <IconButton to='#' name='Start Live Chat' Icon={ RiChatSmile2Line } variant='vertical'/>
            </Box>
          </Box>
          </HeroBoxed.LeftColumn>
          <MemphisPattern />
        </HeroBoxed.Wrapper>
        </Main>
        </Stack>

      <Divider space={3} />
      <Stack>
        <Main>
          {services.mailchimp && (
            <>
              <Divider />
              <NewsletterExpanded simple />
            </>
          )}
        </Main>
      </Stack>
    </>
  )
}

export default Posts
